import React, { useEffect, useState } from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import styled, { createGlobalStyle } from "styled-components";
import { AppLogo } from "../../components/ui";
import theme from "../../util/theme";
import { AnchorLinkButton } from "../../components/ui/AnchorLinkButton";
import { mobileSize } from "../../util/variables";
import Background from "../../content/img/prepayment/prepayment_background.webp";
import { navigate } from "gatsby";
import { CHECKOUT_URL_KEY } from "../special-offer";
import { trackEvent } from "../../services/mixpanel/mixpanel";
import { web_acq_prepayment_success } from "../../services/mixpanel/acquisition-events";
import { fbq } from "../../services/pixel";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";

const PlansPage = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const FAMILY_PLAN = "family-plan";
  const REGULAR_PLAN = "regular-plan";

  const handleOptionClick = (optionId: string) => {
    setSelectedOption(optionId);
    trackEvent("Web_Plan_Page_Select");
  };

  const destinationHref = async () => {
    const checkoutURL = sessionStorage.getItem(CHECKOUT_URL_KEY);
    switch (selectedOption) {
      case FAMILY_PLAN:
        navigate("/imprint/checkout");
        break;
      case REGULAR_PLAN:
        if (checkoutURL?.includes("checkout")) {
          triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_SUCCESS);
          fbq("track", "InitiateCheckout");
          web_acq_prepayment_success();
          document.location.href = checkoutURL;
        } else {
          navigate("/");
        }

        break;
      default:
    }
  };

  useEffect(() => {
    trackEvent("Web_Plan_Page_View");
  }, []);

  return (
    <>
      <GlobalStyle />
      <BasicLayout>
        <Container>
          <Logo>
            <AppLogo size="medium" />
          </Logo>
          <MainContent>
            <FlexItem>
              <Subtitle>20% off unlocked!</Subtitle>
              <Title>Choose a plan for after your 7-day free trial</Title>
            </FlexItem>
            <Options>
              <Option
                selected={selectedOption === FAMILY_PLAN}
                onClick={() => handleOptionClick(FAMILY_PLAN)}
              >
                <OptionCallout>2-6 members</OptionCallout>
                <OptionTitle>Family Plan</OptionTitle>
                <OptionText>
                  <span>$16.66</span>
                  <span> $13.33/mo</span>
                </OptionText>
                <OptionText>12 months</OptionText>
              </Option>
              <Option
                selected={selectedOption === REGULAR_PLAN}
                onClick={() => handleOptionClick(REGULAR_PLAN)}
              >
                <OptionCallout>popular</OptionCallout>
                <OptionTitle>12 Months</OptionTitle>
                <OptionText>
                  <span>$10.42</span>
                  <span> $8.34/mo</span>
                </OptionText>
              </Option>
            </Options>
            <FlexItem>
              <div>
                <p>Billed annually after 7-day trial.</p>
                <p>Cancel anytime.</p>
              </div>
              <AnchorLinkButton
                background={selectedOption ? theme.PRIMARY_COLOR : "#E3E4E5"}
                color={selectedOption ? theme.WHITE_COLOR : theme.SLATE_COLOR}
                disabled={!selectedOption}
                onClick={destinationHref}
              >
                Start your 7-day free trial
              </AnchorLinkButton>
              <Links>
                <a
                  href="https://docs.google.com/document/d/1CF6JLDUCvQpdKegxKuM4Sm96QFlfwhuORv1sEGMfHPw/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </Links>
            </FlexItem>
          </MainContent>
        </Container>
      </BasicLayout>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  section {
    min-height: 100vh;
    background-image: url(${Background});
    background-size: cover;
    background-position: center bottom -80px;
    background-repeat: no-repeat;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: ${theme.SECONDARY_FONT};
`;

const MainContent = styled.div`
  padding: 0px 28px 0 28px;
  display: flex;
  flex-direction: column;
  gap: 8vh;
  align-items: center;
`;

const FlexItem = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    line-height: 1.5;
    font-size: 18px;
    color: ${theme.BLACK_COLOR};
  }
`;

const Subtitle = styled.h2`
  margin: 0;
  text-transform: uppercase;
  color: ${theme.RED};
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  color: ${theme.BLACK_COLOR};
  max-width: 20ch;
`;

const Logo = styled.div`
  display: flex;
  width: 100%;
  padding: 78px 0 60px 80px;
  @media ${mobileSize} {
    padding: 48px 0 40px 26px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  @media (min-width: 500px) {
    max-width: 330px;
  }
`;

const Option = styled.div<{ selected?: boolean }>`
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid ${props => (props.selected ? "#CAFFCB" : "#EDEEEF")};
  background-color: ${props => (props.selected ? "#DAFFDA" : theme.WHITE_COLOR)};
  text-align: left;
  position: relative;
  box-shadow: 0px 2px 8px 0px #a4a4a440;
`;

const OptionTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${theme.BLACK_COLOR};
`;

const OptionText = styled.div`
  font-size: 16px;
  line-height: 24px;
  span {
    padding-right: 2px;
    &:first-child {
      text-decoration: line-through;
      font-weight: 300;
    }
  }
`;

const OptionCallout = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${theme.PURPLE};
  color: white;
  padding: 0px 8px;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
`;

const Links = styled.div`
  font-size: 12px;
  padding-top: 1vw;
  & a {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: underline;
  }
`;

export default PlansPage;
